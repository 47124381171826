<template>
    <div>
        <p>
            ForgetMeNot has you covered for the holidays. Let us remind you to think of your loved ones!
        </p>
    </div>
</template>

<script>

export default {
    name: '',
    components: {
    },
    data: function () {
        return {};
    },
};
</script>

<style scoped>
</style>